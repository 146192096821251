<template>
  <div class="d-flex flex-column align-items-center">
    <div class="message-wrapper max-w-800px">
      <div class="message-meta">
        <div class="reader">
          <p class="reader mb-2 font-weight-bolder">To: {{ message.reader.name }}</p>
          <p class="reader mb-2">{{ message.reader.email }}</p>
          <p class="reader mb-10">{{ message.reader.phone }}</p>
        </div>
        <div class="author">
          <p class=" date font-weight-bold text-right">From: {{ message.author.display_name }}</p>
          <p class=" date font-weight-bold text-right">{{ message.post_date }}</p>
        </div>
      </div>
      <div class="message-header my-10">
        <h1 class="my-2 font-weight-bolder">{{ message.post_title }}</h1>
      </div>
      <div class="message-body">
        <div class="message-content" v-html="message.post_content" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.card {
  border: none;
}
.message-wrapper {
  .message-meta {
    font-size: 1.1em;
  }

  .message-header {
    border-top: 3px solid #f5f5f5;
    border-bottom: 2px solid #f5f5f5;
    padding: 10px;
    font-size: 2em !important;
    line-height: 1.5em;
  }

  .message-content {
    font-size: 1.4em;
    padding-bottom: 1em;
    font-family: Lora, Georgia, "Times New Roman", Times, serif;
    p {
      margin-bottom: 1.5rem;
    }

    ol,
    ul,
    dl {
      margin-left: 20px;
      li {
        padding-left: 10px;
      }
    }
  }
}
</style>

<script>
//IMPORT MODULDES
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { VIEW_MESSAGE } from "@/core/services/store/message.module";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";

export default {
  name: "MessageViewer",

  data: function() {
    return {
      message: {
        post_title: "",
        post_content: "",
        reader: {
          name: "",
          email: "",
          phone: ""
        },
        author: {
          display_name: ""
        }
      }
    };
  },

  computed: {},

  mounted() {
    // set breadcrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Messages", route: "/messages" },
      { title: "Edit", route: "/messages/view" }
    ]);

    // get the message to view
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    const messageKey = this.$route.params.key;
    this.$store
      .dispatch(VIEW_MESSAGE, messageKey)

      .then(response => {
        this.message = response;
      })

      .catch(error => {
        console.log(error.message);
      })

      .finally(() => {
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      });
  }
};
</script>
